//import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate 
} from "react-router-dom";


import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight, faBars, faEnvelope, faPhone, faSoap, faHome, faBroom, faBuilding, faStar, faFile } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import './main.scss';

import RootPageController from './components/root/rootPageController';

library.add( faFacebook, faWhatsapp, faChevronLeft, faChevronRight, faBars, faEnvelope, faPhone, faSoap, faHome, faBroom, faBuilding, faStar, faFile )

function App() {
  return (
    <Router>
      <div className="App">
        <div className="content">
          <Routes>
            <Route path='/' element={<RootPageController/>} />
            <Route path='*' element={<Navigate replace to="/" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;