import React, { useRef } from 'react';

import rootStyle from './root.module.scss';

import IntroContent from '../pages/IntroContent';
import ServicesStrip from '../strips/ServicesStrip';
import AboutContent from '../pages/AboutContent';
import Gallery from '../pages/Gallery';
import TestimonialsContent from '../pages/TestimonialsContent';
// import RecruitmentContent from '../pages/RecruitmentContent';
import ContactContent from '../pages/ContactContent';
import HeaderStrip from "../strips/HeaderStrip";
import Header from "../base/header";
import Footer from "../base/footer";
import HousekeepingContent from '../pages/HousekeepingContent';

const RootPageController = () => {
  const aboutPageRef = useRef(null);
  const servicesPageRef = useRef(null);
  const galleryPageRef = useRef(null);
  const reviewsPageRef = useRef(null);
  const contactPageRef = useRef(null);
  const introPageRef = useRef(null);
  const recruitmentPageRef = useRef(null);

  const executeScroll = (ref) => {
    window.scrollTo({
      behavior: "smooth",
      top: ref.current.offsetTop - 150
    });
  }

  return (
    <>
      <div className={rootStyle.rootContainer}>
        <Header 
          executeScroll={executeScroll} 
          aboutPageRef={aboutPageRef} 
          servicesPageRef={servicesPageRef} 
          galleryPageRef={galleryPageRef} 
          reviewsPageRef={reviewsPageRef} 
          contactPageRef={contactPageRef}
          introPageRef={introPageRef}
        />
        <HeaderStrip/>
        <IntroContent 
          executeScroll={executeScroll} 
          contactPageRef={contactPageRef} 
          introPageRef={introPageRef}
        />    
        <ServicesStrip servicesPageRef={servicesPageRef}/>
        <AboutContent aboutPageRef={aboutPageRef}/>
        <HousekeepingContent/>
        <Gallery galleryPageRef={galleryPageRef}/>
        <TestimonialsContent reviewsPageRef={reviewsPageRef}/>
        {/* <RecruitmentContent recruitmentPageRef={recruitmentPageRef}/> */}
        <ContactContent contactPageRef={contactPageRef}/>
        <Footer
          executeScroll={executeScroll} 
          aboutPageRef={aboutPageRef} 
          servicesPageRef={servicesPageRef} 
          introPageRef={introPageRef} 
          galleryPageRef={galleryPageRef} 
          reviewsPageRef={reviewsPageRef} 
          contactPageRef={contactPageRef}
        />
      </div>
    </>
  );
}

export default RootPageController;