import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import introStyle from './Intro.module.scss';
import QuoteForm from '../forms/QuoteForm';

import HtmlReactParser from 'html-react-parser';

const IntroContent = (props) => {

    const [pageText, setPageText] = useState(null);
    const [loading, setLoading] = useState(true);
    const [url] = useState(window._env_.API_URL + "/api/collections/get/Pages");
    const [urlError, setUrlError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await fetch(url).then((response) => {
                if (response.status >= 400 && response.status < 600) {
                    throw new Error("Bad response from server");
                }
                return response;
            }).then((returnedResponse) => {
                return returnedResponse.json()
            }).then((data) => {
                setPageText(data['entries'][0]);
                setLoading(false);
            }).catch((error) => {
                setUrlError(true);
                setLoading(false);
                console.log(error)
            });
        }
        fetchData();
    }, [])

    return (
        <div ref={props.introPageRef} className={introStyle.introContainer}>
            <div className="page">
                <div className={introStyle.split}>
                    <div>
                        {
                            urlError | !pageText ? (
                                <>
                                    <h2>Dinamic Services</h2>
                                    <p>Could not load page content</p>
                                </>
                            ) : (
                                <>
                                    <h2>{ pageText.Title }</h2>
                                    { HtmlReactParser(pageText.Body) } 
                                </>
                            )
                        }
                        <button className={introStyle.button} onClick={() => props.executeScroll(props.contactPageRef)}>Get in Touch</button>
                    </div>
                    <QuoteForm/>
                </div>  
            </div>
        </div>
    );
}

export default IntroContent;