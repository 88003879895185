import React, { useState, useEffect } from 'react';
import HtmlReactParser from 'html-react-parser';

import testimonialsStyle from './Testimonials.module.scss';
import Testimonials from '../pages/Testimonials';

const TestimonialsContent = (props) => {

    const [pageText, setPageText] = useState(null);
    //const [loading, setLoading] = useState(true);
    const [url] = useState(window._env_.API_URL + "/api/collections/get/Pages");
    //const [urlError, setUrlError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await fetch(url).then((response) => {
                if (response.status >= 400 && response.status < 600) {
                    throw new Error("Bad response from server");
                }
                return response;
            }).then((returnedResponse) => {
                return returnedResponse.json()
            }).then((data) => {
                setPageText(data['entries'][3]);
                //setLoading(false);
            }).catch((error) => {
                //setUrlError(true);
                //setLoading(false);
                console.log(error)
            });
        }
        fetchData();
    }, [])

    return (   
            <div ref={props.reviewsPageRef} className={testimonialsStyle.testimonialContainer}>
                {
                    !pageText ? (
                        <div className='page'>
                            <div className={testimonialsStyle.textBox}>
                                <h2>Testimonials</h2>
                                <p>Could not load testimonials</p>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='page'>
                                <h2>{ pageText.Title }</h2>
                                {
                                    pageText.Body ? (
                                        <div className={testimonialsStyle.textBox}>
                                            <div>
                                                { HtmlReactParser(pageText.Body) } 
                                            </div>
                                        </div>
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                            <Testimonials/>
                        </>
                    )
                }
            </div>
    ); 
}

export default TestimonialsContent;