import React, { Component, useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import headerStyle from './header.module.scss';
import {ReactComponent as DynamicServicesLogo} from '../../images/DinamicServicesLogo.svg'

const Header = (props) => {

  const [classes, setClasses] = useState(headerStyle.nav);
  
  const clickHandler = () => {
      let lClasses = classes
      if (classes.includes(headerStyle.active)) {
          lClasses = lClasses.replace(headerStyle.active, "")
      } else {
          lClasses = lClasses + " " + headerStyle.active
      }
      setClasses(lClasses)
  }

  // const handleClick = () =>
  //   ref.current.scrollIntoView({
  //   behavior: 'smooth',
  //   block: 'start',
  // });

  return (
    <header>
      <div className="page">
          <div className={headerStyle.mainHeader}>
            <h1><a href="/" aria-label="Home"><DynamicServicesLogo/></a></h1>  
            <span className={headerStyle.mobileButton} onClick={clickHandler}><FontAwesomeIcon icon={['fas', 'bars']}></FontAwesomeIcon></span>
            <ul className={classes}>  
                <li><button onClick={() => props.executeScroll(props.aboutPageRef)}>About Us</button></li>
                <li><button onClick={() => props.executeScroll(props.servicesPageRef)}>Services</button></li>
                <li className={headerStyle.gallery}><button onClick={() => props.executeScroll(props.galleryPageRef)}>Gallery</button></li>
                <li><button onClick={() => props.executeScroll(props.reviewsPageRef)}>Reviews</button></li>
                <li><button onClick={() => props.executeScroll(props.contactPageRef)}>Contact</button></li>
                <li><button onClick={() => props.executeScroll(props.introPageRef)}>Get A Quote</button></li>
            </ul>
          </div>
          <div className={headerStyle.contactHeader}></div>
      </div>
    </header>
  );
}

export default Header;