import React, { useState, useEffect, useRef } from 'react';

import aboutStyle from './AboutContent.module.scss';

import HtmlReactParser from 'html-react-parser';

const AboutContent = (props) => {

    const [pageText, setPageText] = useState(null);
    const [loading, setLoading] = useState(true);
    const [url] = useState(window._env_.API_URL + "/api/collections/get/Pages");
    const [urlError, setUrlError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await fetch(url).then((response) => {
                if (response.status >= 400 && response.status < 600) {
                    throw new Error("Bad response from server");
                }
                return response;
            }).then((returnedResponse) => {
                return returnedResponse.json()
            }).then((data) => {
                setPageText(data['entries'][1]);
                setLoading(false);
            }).catch((error) => {
                setUrlError(true);
                setLoading(false);
                console.log(error)
            });
        }
        fetchData();
    }, [])

    return (
        <>
            <div className='page'>
                <div ref={props.aboutPageRef} className={aboutStyle.aboutContainer}>
                {
                    urlError | !pageText ? (
                        <div className={aboutStyle.error}>
                            <h2>About Dinamic Services</h2>
                            <p>Could not load page content</p>
                        </div>
                    ) : (
                        <>
                            { HtmlReactParser(pageText.Body) } 
                        </>
                    )
                }
                </div>
            </div>
        </>  
    );
}

export default AboutContent;