import React, { useState, useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import contactStyle from './ContactForm.module.scss';

//const testSiteKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
const liveSiteKey= "6LfZdvYeAAAAACrTvXaisLEVicQmUcm66pITQu3M"

const ContactForm = () => {

    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const subjectRef = useRef(null);
    const messageRef = useRef(null);

    const contactRecaptchaRef = useRef(null);

    const [submitButtonText, setSubmitButtonText] = useState('Send');
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [name, setName] = useState('');
    const [nameClass, setNameClass] = useState('');
    const [nameValid, setNameValid] = useState(false);

    useEffect(() => {
        if (name) { 
            setNameClass('valid')
            setNameValid(true)
        } else {
            setNameClass('invalid')
            setNameValid(false)
        }
    }, [name])

    const [email, setEmail] = useState('');
    const [emailClass, setEmailClass] = useState('');
    const [emailValid, setEmailValid] = useState(false);

    useEffect(() => {
        if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) { 
            setEmailClass('valid')
            setEmailValid(true)
        } else {
            setEmailClass('invalid')
            setEmailValid(false)
        }
    }, [email])

    const [subject, setSubject] = useState('');
    const [subjectClass, setSubjectClass] = useState('');
    const [subjectValid, setSubjectValid] = useState(false);

    useEffect(() => {
        if (subject) { 
            setSubjectClass('valid')
            setSubjectValid(true)
        } else {
            setSubjectClass('invalid')
            setSubjectValid(false)
        }
    }, [subject])

    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');
    const [messageValid, setMessageValid] = useState(false);

    useEffect(() => {
        if (message) { 
            setMessageClass('valid')
            setMessageValid(true)
        } else {
            setMessageClass('invalid')
            setMessageValid(false)
        }
    }, [message])

    const [phone, setPhone] = useState('');

    const [showAlertBox, setShowAlertBox] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [success, setSuccess] = useState(false);

    const handleChange = e => {
        switch(e.target.name) {
            case 'contact-name':
                setName((nameRef.current.value))
                break;
            case 'email':
                setEmail(emailRef.current.value)
                break;
            case 'subject':
                setSubject(subjectRef.current.value)
                break;
            case 'message':
                setMessage(messageRef.current.value)
                break; 
            case 'phone':
                setPhone(phoneRef.current.value)
                break;
            default:
                break;
        }
    }

    const handleSubmit = async (e) => {
        setSuccess(false)
        e.preventDefault()
        const token = contactRecaptchaRef.current.execute();
        if (!token) {
            setSuccess(false)
            setShowAlertBox(true)
            setAlertText('Please fill in the form')
        } else if (!nameValid) {
            setSuccess(false)
            setShowAlertBox(true)
            setAlertText('Please enter a valid name')
        } else if (!emailValid) {
            setSuccess(false)
            setShowAlertBox(true)
            setAlertText('Please enter a valid email address')
        } else if (!subjectValid) {
            setSuccess(false)
            setShowAlertBox(true)
            setAlertText('Please enter a valid subject')
        } else if (!messageValid) {
            setSuccess(false)
            setShowAlertBox(true)
            setAlertText('Please enter a valid message')
        } else {
            contactRecaptchaRef.current.reset()
            setDisableSubmit(true)
            setSubmitButtonText('Sending...')
            const dbData = new FormData();
            dbData.append('name', name)
            dbData.append('email', email)
            dbData.append('subject', subject)
            dbData.append('msg', message)
            dbData.append('phone', phone)

            let cockpitFormData = JSON.stringify({
                form: {
                    name: name,
                    email: email,
                    subject: subject,
                    msg: message,
                    phone: phone
                }
            });

            const cockpitRequestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: cockpitFormData
            };
            const requestOptions = {
                method: 'POST',
                body: dbData
            };
            await fetch(window._env_.API_URL + "/api/forms/submit/Contact?token=8cff9cb4151884a40d642b61b43c8d", cockpitRequestOptions)
            await fetch('https://mail.danjbennett.co.uk/contact', requestOptions)
            //await fetch('http://192.168.0.177:8080/contact', requestOptions)
                .then(() => {
                        setSuccess(true)
                        setShowAlertBox(false)
                    }
                );
        }
    }

    return (
        <div className={contactStyle.formWrap}>
            {
                success ? (
                    <div className={ contactStyle.successBox }>
                        <h2>Your message has been sent</h2>
                        <p>We will get back to you soon</p>
                    </div>
                ) : (
                    <>
                        <h2>Get in Touch</h2>
                        <form id="contactForm" onSubmit={handleSubmit}>
                            <label htmlFor="contact-name">Name</label>
                            <input ref={nameRef} id="contact-name" className={contactStyle[`${nameClass}`]} onInput={handleChange} name="contact-name" placeholder="Name *"/>

                            <label htmlFor="email">Email</label>
                            <input ref={emailRef} id="email" className={contactStyle[`${emailClass}`]} onChange={handleChange} name="email" placeholder="Email *"/>
                            
                            <label htmlFor="subject">Subject</label>
                            <input ref={subjectRef} id="subject" className={contactStyle[`${subjectClass}`]} onChange={handleChange} name="subject" placeholder="Subject *"/>
                            
                            <label htmlFor="phone">Phone</label>
                            <input ref={phoneRef} id="phone" className={contactStyle[`${phone}`]} onChange={handleChange} name="phone" placeholder="Phone"/>
                            
                            <label htmlFor="message">Message</label>
                            <textarea  ref={messageRef} id="message" className={contactStyle[`${messageClass}`]} onChange={handleChange} name="message" placeholder="Message *"/>
                            <ReCAPTCHA
                                size="invisible"
                                ref={contactRecaptchaRef}
                                sitekey={liveSiteKey}
                            />
                            <button disabled={disableSubmit} className={contactStyle.button} type="submit">{submitButtonText}</button>
                        </form>
                        
                        {showAlertBox ? <div className={ contactStyle.alertBox }><p>{alertText}</p></div> : null}
                    </>
                )
            }
        </div>
    );
}

export default ContactForm;