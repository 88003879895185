import React, { useState, useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import quoteStyle from './QuoteForm.module.scss';

//const testSiteKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
const liveSiteKey= "6LfZdvYeAAAAACrTvXaisLEVicQmUcm66pITQu3M"

const QuoteForm = () => {

    const serviceTypeRef = useRef(null);
    const nameRef = useRef(null);
    const phoneRef = useRef(null);
    const whenRef = useRef(null);
    const locationRef = useRef(null);
    const emailRef = useRef(null);

    const quoteRecaptchaRef = useRef(null);
    
    const [submitButtonText, setSubmitButtonText] = useState('Request');
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [serviceType, setServiceType] = useState('');
    const [serviceTypeClass, setServiceTypeClass] = useState('');
    const [serviceTypeValid, setServiceTypeValid] = useState(false);

    useEffect(() => {
        if (serviceType) { 
            setServiceTypeClass('valid')
            setServiceTypeValid(true)
        } else {
            setServiceTypeClass('invalid')
            setServiceTypeValid(false)
        }
    }, [serviceType])

    const [name, setName] = useState('');
    const [nameClass, setNameClass] = useState('');
    const [nameValid, setNameValid] = useState(false);

    useEffect(() => {
        if (name) { 
            setNameClass('valid')
            setNameValid(true)
        } else {
            setNameClass('invalid')
            setNameValid(false)
        }
    }, [name])

    const [phone, setPhone] = useState('');
    const [phoneClass, setPhoneClass] = useState('');
    const [phoneValid, setPhoneValid] = useState(false);

    const [today] = useState(new Date().toISOString())

    useEffect(() => {
        if (phone) { 
            setPhoneClass('valid')
            setPhoneValid(true)
        } else {
            setPhoneClass('invalid')
            setPhoneValid(false)
        }
    }, [phone]);

    const [when, setWhen] = useState('');
    const [whenClass, setWhenClass] = useState('');
    const [whenValid, setWhenValid] = useState(false);

    useEffect(() => {
        if (when > today) { 
            setWhenClass('valid')
            setWhenValid(true)
        } else {
            setWhenClass('invalid')
            setWhenValid(false)
        }
    }, [when])

    const [location, setLocation] = useState('');
    const [locationClass, setLocationClass] = useState('');
    const [locationValid, setLocationValid] = useState(false);

    useEffect(() => {
        if (location) { 
            setLocationClass('valid')
            setLocationValid(true)
        } else {
            setLocationClass('invalid')
            setLocationValid(false)
        }
    }, [location])

    const [email, setEmail] = useState('');
    const [emailClass, setEmailClass] = useState('');
    const [emailValid, setEmailValid] = useState(false);

    useEffect(() => {
        if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) { 
            setEmailClass('valid')
            setEmailValid(true)
        } else {
            setEmailClass('invalid')
            setEmailValid(false)
        }
    }, [email])

    const [showAlertBox, setShowAlertBox] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [success, setSuccess] = useState(false);

    const handleChange = e => {
        switch(e.target.name) {
            case 'service-type':
                setServiceType((serviceTypeRef.current.value))
                break;
            case 'name':
                setName((nameRef.current.value))
                break;
            case 'phone':
                setPhone(phoneRef.current.value)
                break;
            case 'when':
                setWhen(whenRef.current.value)
                break;
            case 'location':
                setLocation(locationRef.current.value)
                break;
            case 'email':
                setEmail(emailRef.current.value)
                break;
            default:
                break;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const token = quoteRecaptchaRef.current.execute();
        setSuccess(false)
        // something is taking ages here, await?
        if (!emailValid) {
            setSuccess(false)
            setShowAlertBox(true)
            setAlertText('Please enter a valid email address')
        } else if (!serviceTypeValid) {
            setSuccess(false)
            setShowAlertBox(true)
            setAlertText('Please select a service type')
        }  else if (!nameValid) {
            setSuccess(false)
            setShowAlertBox(true)
            setAlertText('Please enter a valid name')
        }  else if (!phoneValid) {
            setSuccess(false)
            setShowAlertBox(true)
            setAlertText('Please enter a valid phone number')   
        } else if (!whenValid) {
            setSuccess(false)
            setShowAlertBox(true)
            setAlertText('Please enter a future date')  
        } else if (!locationValid) {
            setSuccess(false)
            setShowAlertBox(true)
            setAlertText('Please enter a valid location')
        } else if (!token) {
            setSuccess(false)
            setShowAlertBox(true)
            setAlertText('Please fill in the form')
            console.log("token error")
        } else {
            quoteRecaptchaRef.current.reset();
            setShowAlertBox(false)
            setDisableSubmit(true)
            setSubmitButtonText('Sending...')
            const dbData = new FormData();
            dbData.append('serviceType', serviceType)
            dbData.append('name', name)
            dbData.append('phone', phone)
            dbData.append('when', when)
            dbData.append('location', location)
            dbData.append('email', email)

            let cockpitFormData = JSON.stringify({
                form: {
                    serviceType: serviceType,
                    name: name,
                    phone: phone,
                    when: when,
                    location: location,
                    email: email
                }
            });

            const cockpitRequestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: cockpitFormData
            };
            const requestOptions = {
                method: 'POST',
                body: dbData
            };
            
            await fetch(window._env_.API_URL + "/api/forms/submit/Quote?token=9b1f95495dee06871c216f4c29ba31", cockpitRequestOptions)
            await fetch('https://mail.danjbennett.co.uk/quote', requestOptions)
            //await fetch('http://192.168.0.177:8080/quote', requestOptions)
                .then(() => {
                    setSuccess(true)
                }
            );
        }
    }

    return (
        <div className={quoteStyle.formWrap}>
            {
                success ? (
                    <div className={ quoteStyle.successBox }>
                        <h2>Your quote has been sent</h2>
                        <p>We will get back to you soon about your request</p>
                    </div>
                ) : (
                    <>
                        <h2>Get A Quote</h2>
                        <form id="quoteForm" onSubmit={handleSubmit}>
                            <label htmlFor="service-type">Service Type</label>
                            <div className={quoteStyle.customSelect}>
                                <select ref={serviceTypeRef} defaultValue={'DEFAULT'} id="service-type" className={quoteStyle[`${serviceTypeClass}`]} onChange={handleChange} name="service-type" placeholder="Service Type *">
                                    <option value="DEFAULT" disabled hidden>Service Type *</option>
                                    <option value="Deep Cleaning">Deep Cleaning</option>
                                    <option value="End of Tenancy Cleaning">End of Tenancy Cleaning</option>
                                    <option value="Regular Cleaning">Regular Cleaning</option>
                                    <option value="Office Cleaning">Office Cleaning</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>

                            <label htmlFor="name">Name</label>
                            <input ref={nameRef} id="name" className={quoteStyle[`${nameClass}`]} onChange={handleChange} name="name" placeholder="Full Name *"/>
                            
                            <label htmlFor="phone">Phone</label>
                            <input ref={phoneRef} id="phoneno" className={quoteStyle[`${phoneClass}`]} onChange={handleChange} name="phone" placeholder="Phone *"/>

                            <label htmlFor="when">When</label>
                            {/* <input ref={whenRef} type="date" id="when" className={quoteStyle[`${whenClass}`]} onChange={handleChange} name="when" placeholder="When *"/> */}
                            <input ref={whenRef} type="text" id="when" className={quoteStyle[`${whenClass}`]} onChange={handleChange} name="when" onFocus={(e) => { e.target.type='date' }} placeholder="When *"/>
                            
                            <label htmlFor="location">Location</label>
                            <input ref={locationRef} id="location" className={quoteStyle[`${locationClass}`]} onChange={handleChange} name="location" placeholder="Location *"/>

                            <label htmlFor="quoteemail">Email</label>
                            <input ref={emailRef} type="email" id="quoteemail" className={quoteStyle[`${emailClass}`]} onChange={handleChange} name="email" placeholder="Email *"/>
                        
                            <button disabled={disableSubmit} className={quoteStyle.button} type="submit">{submitButtonText}</button>
                            <ReCAPTCHA
                                size="invisible"
                                ref={quoteRecaptchaRef}
                                sitekey={liveSiteKey}
                            />
                        </form>
                        
                        {showAlertBox ? <div className={ quoteStyle.alertBox }><p>{alertText}</p></div> : null}
                    </>
                )
            }
        </div>
    );
}

export default QuoteForm;