import React from 'react';
// import HtmlReactParser from 'html-react-parser';

import wave from '../../images/wave.png';
import ContactForm from '../forms/ContactForm';

import contactStyle from './ContactContent.module.scss';

const ContactContent = (props) => {

    // state = {
    //     pageText: null,
    //     loading: true,
    //     url: window._env_.API_URL + "/api/collections/get/Pages",
    //     urlError: false,
    // };
    
    // async componentDidMount() {
    //     await fetch(this.state.url).then((response) => {
    //         if (response.status >= 400 && response.status < 600) {
    //           throw new Error("Bad response from server");
    //         }
    //         return response;
    //     }).then((returnedResponse) => {
    //         return returnedResponse.json()
    //     }).then((data) => {
    //        this.setState({ pageText: data['entries'][3], loading: false});
    //     }).catch((error) => {
    //       this.setState({ urlError: true, loading: false});
    //       console.log(error)
    //     });
    // }

    return (   
            <div ref={props.contactPageRef} className={contactStyle.contactContainer}>
                <ContactForm/>
                <img src={wave} alt="wave"/>
            </div>
    );
}

export default ContactContent;