
import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import headerStripStyle from './HeaderStrip.module.scss';

//import ReactHtmlParser from 'react-html-parser';


const HeaderStrip = () => {
    // state = {
    //     pageText: null,
    //     loading: true,
    //     url: "https://cockpit.danjbennett.co.uk/api/collections/get/PageContent"
    // };
    
    // async componentDidMount() {
    //     const response = await fetch(this.state.url);
    //     const data = await response.json();
    //     this.setState({ pageText: data['entries'][0], loading: false});
    // }

    return (
        <div className={headerStripStyle.HeaderStrip}>
            <ul>
                <li><Link target="_blank" rel="noreferrer" aria-label="phone" to="#" onClick={(e) => { window.location.href = "tel:07435412398"; e.preventDefault();}}><FontAwesomeIcon icon={['fas', 'phone']}/> <span>07435412398</span></Link></li>
                <li><Link target="_blank" rel="noreferrer" aria-label="email" to="#" onClick={(e) => { window.location.href = "mailto:info@dinamicservices.co.uk"; e.preventDefault();}}><FontAwesomeIcon icon={['fas', 'envelope']}/> <span>info@dinamicservices.co.uk</span></Link></li>
                <li><Link target="_blank" rel="noreferrer" aria-label="facebook" to="//www.facebook.com/dinamicservices.co.uk/"><FontAwesomeIcon icon={['fab', 'facebook']}/> <span>facebook.com/dinamicservices.co.uk</span></Link></li>
                <li><Link target="_blank" rel="noreferrer" aria-label="whatsapp" to="//wa.me/+447435412398/"><FontAwesomeIcon icon={['fab', 'whatsapp']}/> <span>WhatsApp</span></Link></li>
            </ul>
        </div>
    );
}

export default HeaderStrip;