import React, { useState, useEffect } from 'react';

import housekeepingStyle from './HousekeepingContent.module.scss';

import HtmlReactParser from 'html-react-parser';

const HousekeepingContent = (props) => {

    const [pageText, setPageText] = useState(null);
    //const [loading, setLoading] = useState(true);
    const [url] = useState(window._env_.API_URL + "/api/collections/get/Pages");
    const [urlError, setUrlError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await fetch(url).then((response) => {
                if (response.status >= 400 && response.status < 600) {
                    throw new Error("Bad response from server");
                }
                return response;
            }).then((returnedResponse) => {
                return returnedResponse.json()
            }).then((data) => {
                setPageText(data['entries'][5]);
                //setLoading(false);
            }).catch((error) => {
                setUrlError(true);
                //setLoading(false);
                console.log(error)
            });
        }
        fetchData();
    }, [])

    return (
        <>
            <div className='page'>
                <div ref={props.aboutPageRef} className={housekeepingStyle.housekeepingContainer}>
                {
                    urlError | !pageText ? (
                        <>
                            <h2>Housekeeping Service</h2>
                            <p>Could not load page content</p>
                        </>
                    ) : (
                        <>
                            {/* <h2>{ pageText.Title }</h2> */}
                            { HtmlReactParser(pageText.Body) } 
                        </>
                    )
                }
                </div>
            </div>
        </>  
    );
}

export default HousekeepingContent;