import React, { useState, useEffect } from 'react';
import HtmlReactParser from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import galleryStyle from './Gallery.module.scss';

import wave from '../../images/wave.png';

const Gallery = (props) => {

    const [pageText, setPageText] = useState(null);
    const [slideshowImages, setSlideshowImages] = useState(null);
    const [loading, setLoading] = useState(true);
    const [imagesLoading, setImagesLoading] = useState(true);
    const [url] = useState(window._env_.API_URL + "/api/collections/get/Pages");
    const [urlError, setUrlError] = useState(false);
    const [slideshowUrl] = useState(window._env_.API_URL + "/api/collections/get/Gallery");
    const [slideshowUrlError, setSlideshowUrlError] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await fetch(url).then((response) => {
                if (response.status >= 400 && response.status < 600) {
                    throw new Error("Bad response from server");
                }
                return response;
            }).then((returnedResponse) => {
                return returnedResponse.json()
            }).then((data) => {
                setPageText(data['entries'][2]);
                setLoading(false);
            }).catch((error) => {
                setUrlError(true);
                setLoading(false);
                console.log(error)
            });
        }
        
        async function fetchImageData() {
            await fetch(slideshowUrl).then((response) => {
                if (response.status >= 400 && response.status < 600) {
                  throw new Error("Bad response from server");
                }
                return response;
            }).then((returnedResponse) => {
                return returnedResponse.json()
            }).then((imageData) => {
                setSlideshowImages(imageData['entries'][0]['Image'])
                setImagesLoading(false)
            }).catch((error) => {
                setSlideshowUrlError(true)
                setLoading(false)
                console.log(error)
            });
        }

        fetchData();
        fetchImageData();
    }, [])

    const getImageStyle = (url) => {
        return {
            backgroundImage: 'url(' + url + ')'
        }
    }

    const NextButton = ({currentSlide, slideCount, ...props}) => ( <FontAwesomeIcon {...props} icon={['fas', 'chevron-right']}></FontAwesomeIcon> )
    const PrevButton = ({currentSlide, slideCount, ...props}) => ( <FontAwesomeIcon {...props} icon={['fas', 'chevron-left']}></FontAwesomeIcon> )
    const settings = {
        dots: false,
        arrows: true,
        nextArrow: <NextButton></NextButton>,
        prevArrow: <PrevButton></PrevButton>,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 10000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1850,
              settings: {
                slidesToShow: 3
              }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    const openImage = (index) => {
        setIsOpen(true);
        setPhotoIndex(index);
    }

    return (   
            <div ref={props.galleryPageRef} className={galleryStyle.galleryContainer}>
                {
                    !pageText ? (
                        <div className={galleryStyle.textBox}>
                            <h2>Gallery</h2>
                            <p>Could not load gallery content</p>
                        </div>
                    ) : (
                        <div className='page'>
                            <h2>{ pageText.Title }</h2>
                            {
                                !slideshowImages ? (
                                    null
                                ) : (
                                    <Slider className={galleryStyle.gallerySlick} {...settings}>
                                    {
                                        slideshowImages.map((item, index) =>  {
                                            return (<div key={item.meta.asset}><div onClick={() => openImage(index)} className={galleryStyle.item} style={getImageStyle(item.path)}></div></div>);
                                        })
                                    }
                                    </Slider>
                                )
                            }
                            {
                                pageText.Body ? (
                                    <div className={galleryStyle.textBox}>
                                        <div>
                                            { HtmlReactParser(pageText.Body) } 
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    )
                }
                {
                    isOpen && loading === false && isMobile === false ? (
                        <Lightbox
                            mainSrc={window._env_.API_URL + slideshowImages[photoIndex].path}
                            nextSrc={window._env_.API_URL + slideshowImages[(photoIndex + 1) % slideshowImages.length]}
                            prevSrc={window._env_.API_URL + slideshowImages[(photoIndex + slideshowImages.length - 1) % slideshowImages.length]}
                            onCloseRequest={() => {
                                document.body.style.overflow = '';
                                setIsOpen(false)
                            }}
                            toolbarButtons={null}
                            clickOutsideToClose={true}
                            onImageLoad={() => {document.body.style.overflow = 'hidden';}}
                            enableZoom={true}
                            onMovePrevRequest={() =>
                                setPhotoIndex((photoIndex + slideshowImages.length - 1) % slideshowImages.length)
                            }
                            onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % slideshowImages.length)
                            }
                        />
                    ) : (
                        null
                    )  
                }
                <img src={wave} alt="wave"/>
            </div>
    );
}

export default Gallery;