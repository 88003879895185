import React from 'react';
// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import servicesStripStyle from './ServicesStrip.module.scss';

//import ReactHtmlParser from 'react-html-parser';

const ServicesStrip = (props) => {
    // state = {
    //     pageText: null,
    //     loading: true,
    //     url: "https://cockpit.danjbennett.co.uk/api/collections/get/PageContent"
    // };
    
    // async componentDidMount() {
    //     const response = await fetch(this.state.url);
    //     const data = await response.json();
    //     this.setState({ pageText: data['entries'][0], loading: false});
    // }

    return (
        <div ref={props.servicesPageRef} className={servicesStripStyle.servicesStrip}>
            <div className="page">
                <ul>
                    <li>
                        <FontAwesomeIcon icon={['fas', 'soap']}/>
                        <h3>Deep Cleaning</h3>
                        <span>We provide deep cleaning services</span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={['fas', 'home']}/>
                        <h3>End of Tenancy</h3>
                        <span>We provide end of tenancy cleaning</span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={['fas', 'broom']}/>
                        <h3>Regular Cleaning</h3>
                        <span>We provide regular cleaning services</span>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={['fas', 'building']}/>
                        <h3>Office Cleaning</h3>
                        <span>We provide office cleaning services</span>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default ServicesStrip;