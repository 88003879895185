import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import HtmlReactParser from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import testimonialStyle from './Testimonials.module.scss';

const Testimonials = () => {

    const [testimonials, setTestimonials] = useState(null);
    const [loading, setLoading] = useState(true);
    const [url] = useState(window._env_.API_URL + "/api/collections/get/Testimonials");
    //const [urlError, setUrlError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await fetch(url).then((response) => {
                if (response.status >= 400 && response.status < 600) {
                    throw new Error("Bad response from server");
                }
                return response;
            }).then((returnedResponse) => {
                return returnedResponse.json()
            }).then((data) => {
                setTestimonials(data['entries']);
                setLoading(false);
            }).catch((error) => {
                //setUrlError(true);
                setLoading(false);
                console.log(error)
            });
        }
        fetchData();
    }, [])

    const drawStars = (rating) => {
        var stars = Array();
        for (var i = 0; i < 5; i++) {
            if (i <= rating - 1) {
                stars[i] = (<FontAwesomeIcon key={i} className={testimonialStyle.star} icon={['fas', 'star']}></FontAwesomeIcon>);
            } else {
                stars[i] = (<FontAwesomeIcon key={i} className={testimonialStyle.starEmpty} icon={['fas', 'star']}></FontAwesomeIcon>);
            }
        }
        stars = [stars[0], stars[1], stars[2], stars[3], stars[4]]
        return <div>{ stars }</div>;
    }

    const NextButton = ({currentSlide, slideCount, ...props}) => ( <FontAwesomeIcon {...props} icon={['fas', 'chevron-right']}></FontAwesomeIcon> )
    const PrevButton = ({currentSlide, slideCount, ...props}) => ( <FontAwesomeIcon {...props} icon={['fas', 'chevron-left']}></FontAwesomeIcon> )
    const settings = {
        dots: false,
        infinite: true,
        nextArrow: <NextButton></NextButton>,
        prevArrow: <PrevButton></PrevButton>,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                }
            }
        ]
    };

    return (
        <div className={testimonialStyle.testimonials}>
            <div className="page">
                <Slider className={testimonialStyle.testimonialSlick} {...settings}>
                    {
                        loading || !testimonials ? (
                            null
                        ) : (
                            testimonials.map((item, index) => 
                                <div key={item._id}>
                                    <div className={testimonialStyle.testimonial}>
                                        { HtmlReactParser(item.Quote) }
                                        <span>{item.Name}</span>
                                        {
                                            drawStars(item.Rating)
                                        }
                                    </div>
                                </div>
                            )
                        )
                    }
                </Slider>
            </div>
        </div>
    );
}

export default Testimonials;