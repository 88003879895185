import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import footerStyle from './footer.module.scss';
import {ReactComponent as DynamicServicesLogo} from '../../images/DinamicServicesLogo.svg'

const Footer = (props) => {
  return (
    <footer>
        <div className={footerStyle.footerInner}>
          <div className={footerStyle.links}>
            <section>
              <h3>Navigation</h3>
              <ul className={footerStyle.navigation}>
                <li><button onClick={() => props.executeScroll(props.aboutPageRef)}>About Us</button></li>
                <li><button onClick={() => props.executeScroll(props.servicesPageRef)}>Services</button></li>
                <li><button onClick={() => props.executeScroll(props.introPageRef)}>Get A Quote</button></li>
                <li><button onClick={() => props.executeScroll(props.galleryPageRef)}>Gallery</button></li>
                <li><button onClick={() => props.executeScroll(props.reviewsPageRef)}>Reviews</button></li>
                <li><button onClick={() => props.executeScroll(props.contactPageRef)}>Contact</button></li>
              </ul>
            </section>
            <section>
              <h3>Connect</h3>
              <ul className={footerStyle.connect}>
                <li><Link target="_blank" rel="noreferrer" aria-label="whatsapp" to="//wa.me/+447435412398/"><FontAwesomeIcon icon={['fab', 'whatsapp']}/> <span>WhatsApp</span></Link></li>
                <li><Link target="_blank" rel="noreferrer" aria-label="email" to="#" onClick={(e) => { window.location.href = "mailto:info@dinamicservices.co.uk"; e.preventDefault();}}><FontAwesomeIcon icon={['fas', 'envelope']}/> <span>info@dinamicservices.co.uk</span></Link></li>
                <li><Link target="_blank" rel="noreferrer" aria-label="facebook" to="//www.facebook.com/dinamicservices.co.uk/"><FontAwesomeIcon icon={['fab', 'facebook']}/> <span>facebook.com/dinamicservices</span></Link></li>
              </ul>
            </section>
            <section>
              <h3>Opening Hours</h3>
              <ul className={footerStyle.opening}>
                <li><span>Sun: Closed</span></li>
                <li><span>Mon-Sat: 9am-5pm</span></li>
                <li><Link target="_blank" rel="noreferrer" aria-label="phone" to="#" onClick={(e) => { window.location.href = "tel:07435412398"; e.preventDefault();}}><FontAwesomeIcon icon={['fas', 'phone']}/> <span>07435412398</span></Link></li>
              </ul>
            </section>
          </div>
          <div className={footerStyle.info}>
            <a href="/" aria-label="Home"><DynamicServicesLogo/></a>
            <p>© Dinamic Services, 2022</p>
            <p>All right reserved.</p>
          </div>
        </div>
    </footer>
  );
}

export default Footer;